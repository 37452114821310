<template>
  <div class="pane_body">
    <v-card flat color="rgb(255 255 245)">
      <v-toolbar flat dense class="breads">
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              text
              v-on="on"
              color="success"
              @click.stop="newDialog = true"
            >
              new
            </v-btn>
          </template>
          <span>Create new Doctor Subscriber</span>
        </v-tooltip>
        <SubscriberNew
          :newDialog="newDialog"
          :subType="subscriberType"
          @closeNew="closeNew"
          @submitNew="submitNew"
        />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              text
              v-on="on"
              color="info"
              @click.stop="uploadDialog = true"
            >
              <v-icon>mdi-cloud-upload-outline</v-icon>
            </v-btn>
          </template>
          <span>Upload</span>
        </v-tooltip>
        <SubscriberUpload
          :uploadDialog="uploadDialog"
          :subType="subscriberType"
          :myRole="3"
          @closeUpload="closeUpload"
          @submitUpload="submitUpload"
        />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" color="info" @click="lists()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-toolbar>
    </v-card>

    <v-container>
      <v-card color="transparent" flat>
        <v-card flat rounded="0">
          <v-card-text>
            <v-data-table
              :loading="loading"
              loader-height="2"
              :headers="headers"
              :items="medics"
              :items-per-page="8"
              :footer-props="{ itemsPerPageOptions: [8, 16, 24] }"
            >
              <template v-slot:item.fullname="{ item }">
                {{ item.subscriber.first_name }} {{ item.subscriber.last_name }}
              </template>
              <template v-slot:item.created="{ item }">
                {{ item.created | myDating }}
              </template>

              <template v-slot:item.active="{ item }">
                <v-icon :color="item.active ? 'blue' : 'red'">
                  {{ item.active ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  @click="
                    detail(
                      item.subscriber.id,
                      item.subscriber.first_name,
                      item.subscriber.last_name
                    )
                  "
                  text
                  x-small
                  color="primary"
                >
                  view
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-container>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
      timeout="-1"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils";
import SubscriberNew from "../../../components/subscribers/newone.vue";
import SubscriberUpload from "../../../components/subscribers/uploads.vue";
export default {
  mixins: [Utils],
  components: { SubscriberNew, SubscriberUpload },
  data() {
    return {
      loading: false,
      newDialog: false,
      uploadDialog: false,
      subscriberType: 4,
      breadcrumb: [
        {
          text: "Dashboard",
          disabled: false,
          to: { name: "admin.dash" },
        },
        {
          text: "Delivery Services",
          disabled: true,
        },
      ],
      headers: [
        { text: "first name", value: "user.first_name" },
        { text: "last name", value: "user.last_name" },
        { text: "email", value: "user.email" },
        { text: "phone number", value: "user.mobile_phone" },
        { text: "active", value: "user.active" },
        { text: "actions", value: "actions", align: "center" },
      ],
      medics: [],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
    };
  },
  created() {
    this.lists();
  },
  methods: {
    lists() {
      this.loading = true;
      let self = this;
      let subscriber_types_id = this.subscriberType;
      let data = { subscriber_types_id };
      Restful.subscribers
        .byType(data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.medics = response.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    detail(id, firstName, lastName) {
      console.log(id);
      this.$router.push({
        name: "admin.medical.details",
        params: { id: id, fname: firstName, lname: lastName },
      });
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.newDialog = p.state;
    },
    closeUpload(p) {
      this.uploadDialog = p.state;
    },
    submitUpload(p) {
      console.log(p);
      this.snack.bar = true;
      console.log(p.data);
      let self = this;
      Restful.subscribers
        .upload(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.uploadDialog = p.state;
    },
  },
};
</script>
